import { DatePipe, Location, LocationStrategy } from '@angular/common';
import { Component, DoCheck, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
// import { MatDialog, MatRadioChange } from '@angular/material';
import { DOCUMENT } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { UserService } from 'src/app/core/services/user.service';
import { ApiResult } from '../../../core/models/api-result';
import { User } from '../../../customer/models/user.model';
import { ManageCustomersService } from '../../../merchant/loyalty-management/components/manage-customers/manage-customers.service';
import { AuthService } from '../../../core/services/authentication/auth.service';
import { SlideUpDownAnimation } from '../../../core/animation/collapse-animate';
import { TypesUtilsService } from '../../../core/services/types-utils.service';
import { InvalidDataValidator } from '../../validators/invalid-data.validator';
import { SpinnerButtonOptions } from '../spinner-button/button-options.interface';
import { TermsAndConditionsPopupComponent } from '../terms-and-conditions-popup/terms-and-conditions-popup.component';
import { ProfileEditService } from './services/profile-edit.service';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { TokenStorage } from 'src/app/core/services/authentication/token-storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss'],
  animations: [SlideUpDownAnimation],
  providers: [ProfileEditService]
})
export class ProfileEditComponent implements OnInit, OnDestroy, DoCheck, OnChanges {

  registerForm: FormGroup;
  // user = new User();
  animationState = 'down';
  minDate: Date = new Date();
  dobRestrict: Date = new Date();
  maxDoB: Date = new Date(this.dobRestrict.setFullYear(this.dobRestrict.getFullYear()-18));
  minDoB: Date = new Date(this.dobRestrict.setFullYear(this.dobRestrict.getFullYear()-90));
  spouseDoBRestrict: Date = new Date();
  maxSpouseDoB: Date = new Date(this.spouseDoBRestrict.setFullYear(this.spouseDoBRestrict.getFullYear()-18));
  minSpouseDoB: Date = new Date(this.spouseDoBRestrict.setFullYear(this.spouseDoBRestrict.getFullYear()-90));
  pipe = new DatePipe('en-IN'); // Use your own locale
  isMobileDisable: boolean;
  fromBtnText = 'Save';
  selPinCode: any;
  isChangeMobile: boolean;
  showOtpFieldFlag: boolean;
  isResendOTP: boolean;
  mobileExists: boolean;
  canSendOtp: boolean;
  canVerify: boolean;
  otpDisable: boolean;
  invalidOtp: boolean;
  otpAttempts: boolean;
  isLoading: boolean;
  otpVerified: boolean;
  spinner: SpinnerButtonOptions = {
    active: false,
    spinnerSize: 18,
    raised: true,
    buttonColor: '',
    spinnerColor: 'primary',
    fullWidth: false,
    mode: 'indeterminate'
  };
  submitted = false;

  spinnerSubmit: SpinnerButtonOptions = {
    active: false,
    spinnerSize: 18,
    raised: true,
    buttonColor: '',
    spinnerColor: 'primary',
    fullWidth: false,
    mode: 'indeterminate'
  };
  subscriptions: Subscription[] = [];
  isRequestForOtp: boolean;
  otp: string;
  searchData: any;
  isSearchInProgress: boolean;
  filteredOptions: any = [];
  optionSelected: boolean;
  disableSearchButton: boolean = false;
  notFound: boolean;
  selectedPincode: any = null;
  currentUser: number;
  profileShow: boolean = true;
  subscription: any;
  isProfileEdit: boolean;
  editSaveButton: any = 'Edit';
  isSave: boolean;  
  @Input() set error(error: any) {
    if (!error) {
      return;
    }
    if (error && (error.statusCode === 105 || error.statusCode === 102)) {
      this.registerForm.enable();
      this.registerForm.get('mobile_number').disable();
    }
    this.spinnerSubmit.active = false;
    // this.setError(error);
  }
  @Input() user: User;
  @Input() isEdit: boolean;
  @Output('submited') userSubmited = new EventEmitter<User>();
  @Output() formState = new EventEmitter<boolean>();
  @Output() isDestroy = new EventEmitter<boolean>();
  @Input() showReg: boolean;
  @Input() isRegister: any;
  @Input() count: any;
  constructor(
    private _formBuilder: FormBuilder,
    public authService: AuthService,
    private managecustomersservice: ManageCustomersService,
    private typesUtilsService: TypesUtilsService,
    private userService: UserService,
    private profileEditService: ProfileEditService,
    private url: LocationStrategy,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private location: Location,
    private router: Router,
    private dialog: MatDialog,
    private tokenStorage: TokenStorage,
    private route: ActivatedRoute
  ) {
    this.isMobileDisable = true;
  }

  public setInitialError(message:any) {       
    const emailControl = this.registerForm.get('email_address');     
    if (emailControl) {      
        emailControl.setErrors({         
            serverError: true, message: message 
        }); 
    } 
  }
  ngOnChanges(message) {      
      this.setInitialError(message.error.currentValue.message);      
  }

  ngDoCheck() {
    if (this.registerForm.valid) {
      this.error = [];
    }
    this.formState.emit(this.registerForm.valid);
  }

  ngOnInit() {    
    if (this.authService.currentUser) {
      this.currentUser = this.authService.currentUser.user_type_id ? this.authService.currentUser.user_type_id : null;
    }
    if (this.showReg) {
      const element = document.querySelector('.scrollEditPanel');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        // element.scrollTop = element.scrollHeight;
      }
    }

    if (this.isPosUser() && !this.isEdit) {
      this.isMobileDisable = false;
    }

    if (!this.isEdit) {
      this.fromBtnText = 'Submit';
      this.user = new User();
      this.user.clear();
    }

    if (this.user.marital_status === 2) {
      this.toggleShowDiv('2');
    }
    this.registerForm = this.createForm();
    this.setValidation();

    if (this.url.path() === '/dashboard/profile') {
      this.profileShow = true;
      this.renderer.addClass(this.document.body, 'profilEdit');
    } else {
      this.profileShow = false;
      this.renderer.removeClass(this.document.body, 'profilEdit');
    }

    if (this.isRegister != 0) {
      this.authService.currentUser.isCustomer ? this.registerForm.disable() : '';
      if (this.authService.currentUser.isCustomer) {
        this.isProfileEdit = false;        
      } else {
        this.isProfileEdit = true;        
      }
    } else {
      this.isProfileEdit = true;      
    }

    this.subscription = this.router.events.pipe(
      filter(event => (event instanceof NavigationEnd)),
    ).subscribe(
      (routeData: NavigationEnd) => {
        if (routeData.url === '/dashboard/profile') {
          this.renderer.addClass(this.document.body, 'profilEdit');
        } else {
          this.renderer.removeClass(this.document.body, 'profilEdit');
        }
      },
      error => { this.subscription.unsubscribe(); },
      () => {
        this.subscription.unsubscribe();
      }
    );

  }

  createForm() {
    const pinCode = (this.user.pin_code == 'N/A') ? null: this.user.pin_code;
    const cityName = (this.user.city_name == 'N/A') ? null: this.user.city_name;
    return this._formBuilder.group({
      first_name: [this.user.first_name],
      last_name: [this.user.last_name],
      email_address: [this.user.email_address],
      mobile_number: [{ disabled: this.isMobileDisable, value: this.user.mobile_number }],
      gender: [this.user.gender],
      date_of_birth: [this.user.date_of_birth],
      marital_status: [this.user.marital_status],
      anniversary_date: [this.user.anniversary_date || null],
      spouse_dob: [this.user.spouse_dob || null],
      otp: [this.user.otp || null],
      pin_code: [pinCode || null],
      city_name: [cityName || null],
      city_id: [this.user.city_id || null],
      terms_conditions: [this.user.terms_conditions || null]
    });
  }

  setValidation() {
    const requiredField = this.profileEditService.requiredField(this.isPosUser());
    const fieldList = this.profileEditService.fieldList();
    const controlValidation = this.profileEditService.fieldValidations();

    fieldList.forEach(element => {

      const control = this.registerForm.get(element);
      const controlRule = controlValidation.find(row => row.key === element);
      const validation = controlRule.validation;

      if (requiredField.indexOf(element) !== -1) {
        validation.push(Validators.required);
      }

      if (validation && validation.length) {
        control.setValidators(validation);
        control.updateValueAndValidity();
      }

    });
    this.initFormChanges();
  }

  isMobileFieldValid(value: any) {
    return value && value.length > 9 && /^[0-9]*$/.test(value);
  }

  mobileValidation(isExist: boolean) {
    const controlValidation = this.profileEditService.fieldValidations();
    const controlRule = controlValidation.find(row => row.key === 'mobile_number');
    const validation = controlRule.validation;
    if (isExist) {
      validation.push(this.isExistValidator(isExist));
    }
    return validation;
  }

  isExistValidator(isExist: boolean = false): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (isExist) {
        return { isExist: true };
      }
      return null;
    };
  }

  initFormChanges() {
    
    if ( !this.submitted ) {
      this.otpVerified = false;
      const mobileControl = this.registerForm.get('mobile_number');
      this.subscriptions.push(mobileControl.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(value => {
          this.otpVerified = false;
          this.isLoading = true;
          if (!this.isMobileFieldValid(value)) {
            mobileControl.setValidators(this.mobileValidation(false));
            mobileControl.updateValueAndValidity();
            this.canSendOtp = false;
            this.isLoading = false;
          }
        }),
        filter(value => this.isMobileFieldValid(value)),
        switchMap(value => !this.submitted ? this.managecustomersservice
          .checkMobileNumber({ mobile_number: value }).pipe(finalize(() => (this.isLoading = false))): null )

      ).subscribe(result => {
        if (result.data.isRegister) {
          mobileControl.setValidators(this.mobileValidation(true));
          mobileControl.markAsDirty();
          this.canSendOtp = false;
        } else {
          if (mobileControl.valid) {
            this.canSendOtp = true;
          }
          mobileControl.setValidators(this.mobileValidation(false));
        }
        this.resetFields();
        mobileControl.markAsTouched();
        mobileControl.updateValueAndValidity();
      }));    
      
    }  
    this.pinCodeValueChange();
  }

  pinCodeValueChange() {
    const pinCodeController = this.registerForm.get('pin_code');
    pinCodeController.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(value => {
          // this.resetCity();
          // this.selectedPincode = null;
          pinCodeController.setValidators(this.pincodeValidation(false));
          pinCodeController.updateValueAndValidity();
        }),
        filter((value) => value && pinCodeController.valid),
        tap(() => {
          this.isSearchInProgress = true;
          // this.resetCity();
        }),
        switchMap(value => {
          if (typeof value === 'object') {
            return of().pipe(finalize(() => (this.isSearchInProgress = false)));
          }

          if (typeof value.isNumber) {
            this.searchData = { pinCode: value };
            return this.profileEditService.getPinCodes(this.searchData).pipe(finalize(() => (this.isSearchInProgress = false)));
          }
          // return of(value).pipe(finalize(() => (this.isSearchInProgress = false)));
        })
      ).subscribe(result => {

        if (typeof result === 'object') {
          this.filteredOptions = result['pin_codes'];
          if (this.selPinCode != this.searchData.pinCode.pin_code || this.searchData.pinCode.pin_code === undefined) {
            if (this.optionSelected && this.filteredOptions.length && this.searchData.pinCode.pin_code) {
              this.disableSearchButton = false;
              // const element = <HTMLElement>document.querySelector('.cdk-overlay-container');
              // element.setAttribute('style', 'pointer-events: inherit!important');
            } else {
              this.disableSearchButton = true;
              // const element = <HTMLElement>document.querySelector('.cdk-overlay-container');
              // element.setAttribute('style', 'pointer-events: none!important');
            }
          }
          this.isSearchInProgress = false;
        }
      });
  }
  // otp: ['' || null, [Validators.minLength(4)]]
  resetFields() {
    if (this.isRequestForOtp) {
      this.isRequestForOtp = false;
      this.showOtpFieldFlag = false;
      this.otpDisable = false;
      this.registerForm.get('otp').reset();
    }

  }
  isMarried(event: MatRadioChange) {
    this.toggleShowDiv(event.value);
    this.updateValidation(event.value);
  }

  toggleShowDiv(maritalStatus: string | null) {
    let slideState: string;
    if (maritalStatus == '2') {
      slideState = 'up';
    } else {
      slideState = 'down';
    }
    this.animationState = slideState;
  }

  _keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  updateValidation(maritalStatus: string | null): void {
    const anniversaryControl = this.registerForm.get('anniversary_date');
    const spouseControl = this.registerForm.get('spouse_dob');

    if (!this.isPosUser() && maritalStatus === '2') {
      // anniversaryControl.setValidators([Validators.required]);
      // spouseControl.setValidators([Validators.required]);
    } else {
      anniversaryControl.clearValidators();
      spouseControl.clearValidators();
    }
    spouseControl.reset();
    anniversaryControl.reset();
    anniversaryControl.updateValueAndValidity();
    spouseControl.updateValueAndValidity();
  }

  submited() {
    this.submitted = true;
    if (this.registerForm.valid ) { 
      this.spinnerSubmit.active = true;
      //this.registerForm.disable();
      let isPincodeError = false;
      const controls = this.registerForm.controls;
      const formValues: User = new User();
      const pinCodeControl = this.registerForm.get('pin_code');
      const pinCodeControlValue = pinCodeControl.value;
      if (this.selectedPincode && !this.isValidValue(this.selectedPincode, pinCodeControlValue)) {
        isPincodeError = true;
      }
      if (isPincodeError) {
        pinCodeControl.setValidators(this.pincodeValidation(true));
        pinCodeControl.updateValueAndValidity();
        this.spinnerSubmit.active = false;
        this.registerForm.enable();
        return false;
      }

      const pinCodeValue = pinCodeControlValue ? pinCodeControlValue.pin_code ? pinCodeControlValue.pin_code : pinCodeControlValue : undefined
      this.registerForm.get('pin_code').setValue(pinCodeValue);

      /** check form */
      const dateColums = ['date_of_birth', 'anniversary_date', 'spouse_dob'];
      Object.keys(controls).forEach(controlName => {
        const controlValue = controls[controlName].value;
        if (dateColums.indexOf(controlName) !== -1) {
          if (controlValue instanceof Date || controlValue) {
            formValues[controlName] = this.typesUtilsService.transformDate(controls[controlName].value);
          }
        } else {
          if (controlValue) {
            if (controlName === 'otp') {
              if (!this.isEdit && this.otpVerified) {
                formValues[controlName] = +controlValue;
              }
            } else {
              formValues[controlName] = controls[controlName].value;
            }
          }
        }
      });

      formValues.request_source = this.isEditMode();
      if (this.isPosUser()) {
        if (this.otpVerified) {
          formValues.mobile_verified = 1;
        }
      }
      this.userSubmited.emit(formValues);
    }  
  }

  isEditMode() {
    return (this.isEdit) ? 201 : 0;
  }
  isPosUser(): boolean {
    if (this.authService.loggedIn) {
      const userType = this.authService.currentUser.user_type_id;
      return userType === 5 || userType === 4;
    }
    return false;
  }

  inputValidator(event: any) {
    const formControlName = event.target.getAttribute('formControlName');
    const pattern = /^[0-9]*$/;
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^0-9]/g, '');
    }
    this.otpAttempts = false;
    if (formControlName === 'otp') {
      const formControlValue = this.registerForm.get(formControlName).value;
      (formControlValue.length === 6) ? this.canVerify = true : this.canVerify = false;
      this.invalidOtp = false;
      this.isResendOTP = false;
    }

  }

  onAction(state: string = 'disable') {
    const control = this.registerForm.get('mobile_number');
    control.disable();

    if (state === 'enable') {
      // control.enable();
    }

    control.updateValueAndValidity();
  }

  changeMobileNumber() {
    this.onAction('enable');
    this.isChangeMobile = true;
  }


  cancelMobileNumber() {
    this.onAction();
    this.isChangeMobile = false;
    this.showOtpFieldFlag = false;

    const otpControl = this.registerForm.get('otp');
    otpControl.setValidators([Validators.required]);
  }

  setError(error: any) {
    // this.registerForm.get('email_address').setErrors({ serverError: true, message: 'INVALID EMAIL' });
    const emailControl = this.registerForm.get('email_address');
    if (emailControl) {   emailControl.setErrors({     serverError: true,     message: 'INVALID EMAIL' }); }      

    const registerForm = this.registerForm;
    if (error.statusCode === 105) {
      registerForm.setErrors({ 'server-error': true, message: error.message });
      return;
    }

    if (error.statusCode === 102) {
      registerForm.get(error.field_name).setErrors({ serverError: true, message: error.message });
      return;
    }

    Object.values(error).forEach(prop => {
      const formControl = this.registerForm.get(prop['key']);
      if (formControl) {
        formControl.setErrors({
          serverError: true,
          message: prop['message']
        });
        formControl.markAsTouched();
      }
    });
  }

  isServerError(result: ApiResult) {
    if (result.data['statusCode'] === 105) {
      this.spinner.active = false;
      this.isRequestForOtp = false;
      return true;
    }
    return false;
  }

  setFormError(result: ApiResult) {
    this.registerForm.setErrors({ 'server-error': true, message: result.message });
  }

  showOtp(result: ApiResult) {
    if (result.data) {
      // this.updateValidation();
      this.spinner.active = false;
      this.showOtpFieldFlag = true;
    }
  }

  checkOtpResponse(result: ApiResult) {
    return this.isServerError(result);
  }

  sendOtp(): void {

    this.spinner.active = true;
    this.isRequestForOtp = true;
    const mobileNumber = this.registerForm.get('mobile_number').value;
    this.subscriptions.push(this.userService.requestOtp({ mobile_number: mobileNumber })
      .pipe(tap((result) => {
        if (this.checkOtpResponse(result)) {
          return;
        }

        if (this.attemptExeed(result)) {
          return;
        }
      })).subscribe(result => {
        this.showOtp(result);
      },
        error => {
          this.spinner.active = false;
          this.isRequestForOtp = false;
        }
      ));
  }

  verifyOtp(): void {
    // this.spinner.active = true;
    this.canVerify = false;
    this.otpDisable = true;
    this.otpVerified = false;
    const mobileControl = this.registerForm.get('mobile_number');
    const otpControl = this.registerForm.get('otp');
    const params = {
      mobile_number: mobileControl.value,
      otp: otpControl.value
    };

    this.subscriptions.push(this.userService.verifyOtp(params).subscribe(result => {
      if (this.attemptExeed(result)) {
        otpControl.reset();
        return;
      }

      if (result.data['statusCode'] === 104) {
        this.otpDisable = false;
        this.isResendOTP = true;
        this.invalidOtp = true;
        // this.loginForm.get('otp').setErrors({ 'invalidOtp': true });
        return;
      }

      if (result.data['isRegister'] === 0) {
        this.otpVerified = true;
      }
    }, error => {
      this.canVerify = true;
      this.otpDisable = false;
      otpControl.reset();
      this.setError(error.error.error.details);
    }));
  }

  reSendOtp(): void {
    this.invalidOtp = false;
    this.isResendOTP = false;
    const otpControl = this.registerForm.get('otp');
    otpControl.reset();
    const mobileNumber = this.registerForm.get('mobile_number');
    const params = { mobile_number: mobileNumber.value };
    this.otpAttempts = false;
    this.subscriptions.push(this.userService.reSendOtp(params)
      .pipe(tap((result) => {
        if (this.attemptExeed(result)) {
          return;
        }
      })).subscribe(result => {
        this.showOtp(result);
      }, error => {
        this.isResendOTP = true;
        // error.error.error.details
      }));
  }

  attemptExeed(result: ApiResult) {
    if (result.data['otp_attempt'] >= 3) {
      this.spinner.active = false;
      this.otpAttempts = true;
      this.resetFields();
      if (result.data['statusCode'] === 104) {
        this.otp = '';

      }
      return true;
    }
    // this.initCountDown(timeLeft);
    return false;
  }

  selectOption(value) {
    this.selectedPincode = value;
    this.optionSelected = true;
    this.notFound = false;
    this.disableSearchButton = false;
    this.setCity(value);
    this.selPinCode = value.pin_code;
    this.filteredOptions = [];
  }

  setCity(cityObj: any) {
    const formCache = this.registerForm;
    formCache.get('city_name').setValue(cityObj.city_name);
    formCache.get('city_id').setValue(cityObj.city_id);
  }

  resetCity() {
    const formCache = this.registerForm;
    formCache.get('city_name').setValue(null);
    formCache.get('city_id').setValue(null);
  }

  displayFn(pincode?: any): string | undefined | any {
    if (typeof pincode === 'object') {
      this.selPinCode = pincode;
      this.selectedPincode = pincode;
    }
    return pincode ? pincode.pin_code ? pincode.pin_code : pincode : undefined;
  }

  isNumber(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode > 47 && charCode < 58)) {
      return true;
    }
    return false;
  }

  pincodeValidation(isSelected: boolean) {
    const controlValidation = this.profileEditService.fieldValidations();
    const controlRule = controlValidation.find(row => row.key === 'pin_code');
    const validation = controlRule.validation;
    if (isSelected) {
      validation.push(InvalidDataValidator(true));
    }
    if (!this.showReg) {
      validation.push(Validators.required);
    }
    return validation;
  }

  isValidValue(selectedPincode: any, input: string): boolean {
    let inputValue = '';
    if (typeof input === 'string') {
      inputValue = input;
    } else {
      inputValue = input['pin_code'];
    }
    return this.selectedPincode && 'pin_code' in this.selectedPincode && this.selectedPincode['pin_code'] === inputValue;
  }

  isValidPinCode(input) {
    const control = this.registerForm.get('pin_code');
    if (control.valid && input && !this.selectedPincode && !this.isValidValue(this.selectedPincode, input)) {
      control.setValidators(this.pincodeValidation(true));
      control.updateValueAndValidity();
    }
  }

  editProfile(isSave) {
    this.registerForm.enable();
    this.registerForm.get('mobile_number').disable();
    var element = <HTMLElement>document.querySelector('a#edit-disable');
    element.classList.remove('disable-change');

    var location = <HTMLElement>document.querySelector('a#edit-disable-location');
    location ? location.classList.remove('disable-change') : '';

    this.editSaveButton = 'Save';

    this.isSave = isSave;
  }

  getTermsConditions() {
    const dialogRef = this.dialog.open(TermsAndConditionsPopupComponent, {
      autoFocus: true,
      disableClose: true,
      // maxWidth: '380px',
      panelClass: 'terms-conditions'
    });
    dialogRef.afterClosed().pipe()
  }

  ngOnDestroy() {
    this.isDestroy.emit(true);
    this.user = new User();
    this.user.clear();
    this.isEdit = false;
    this.isRequestForOtp = false;
    this.error = [];
    this.subscription.unsubscribe();
  }

  cancel() {
    this.location.back();
  }

  isRequired(fieldName: string): boolean {
    const requiredField = this.profileEditService.requiredField(this.isPosUser(), this.isEdit);
    if (requiredField.indexOf(fieldName) !== -1) {
      return true;
    }
    return false;
  }

  skipProfile($event){
    $event.preventDefault();
    const mobileControl = this.registerForm.get('mobile_number');
    const otpControl = this.registerForm.get('otp');
    const params = {
      mobile_number: mobileControl.value,
      otp: otpControl.value
    };

    this.subscriptions.push(this.userService.verifyOtpSkipAction(params).subscribe(result => {
      //console.log(result);
      this.tokenStorage.setAccessToken(result.data.userData).setUserActions(result.data.userData).setDefaultPath(result.data.userData);
      this.authService.setCurrentUser();
      let returnUrl = '/dashboard/index';
      /*if ((returnUrl = this.route.snapshot.queryParamMap.get('returnUrl')) !== null) {
         localStorage.setItem(environment.returnUrl, returnUrl);
      } else {
         returnUrl = this.tokenStorage.getDefaultPath();
      }*/
      this.router.navigateByUrl(returnUrl);
      // console.log(result.data);
      return false;
    }, error => {

    }));   
  }
}
