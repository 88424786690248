// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // serverUrl: 'http://localhost:8081',   
  // serverUrl: 'http://192.168.151.227:8081',
  serverUrl: 'https://stage-crocs.boomerup.com:8443',
  encriptionKey: 'crossSaas@sec#pass123',
  accessTokenParam: 'csAn',
  lsSecureKey: 'cross-saas',
  returnUrl: 'cRUl',
  userInfo: 'csUf',
  defaultPath: 'defaultPath',
  currentNavigateRoute: 'cNt',
  campaign: 'campaign'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
