import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoreGuard implements CanActivate {
  constructor(private router:Router) {}
  private validStores:string[]=['s1c1','s2c2', 'ofvx1nMr8pXZS', 'JK3YxYMo3tRPS','ptdkj2Uk7fWUD', 'LocalW98cHvT', 'LocalV18XjEb', 'STGW98cHvT', 'STGV18XjEb' ]  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const store = next.params['store'];
    if (this.validStores.includes(store)) {
      return true;
    } else {
      this.router.navigate(['**']);
      return false;
    }
  }
}
