import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/components/login/login.component';
import { RegisterComponent } from './auth/components/register/register.component';
import { RegisterGuard } from './core/services/authentication/register.guard';
import { CancelMobileChangeRequestComponent } from './shared/components/cancel-mobile-change-request/cancel-mobile-change-request.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { OtpInComponent } from './shared/components/otp-in/otp-in.component';
import { OtpOutComponent } from './shared/components/otp-out/otp-out.component';
import { TermsAndConditionsComponent } from './shared/components/terms-and-conditions/terms-and-conditions.component';
import { VerifyEmailAddressComponent } from './shared/components/verify-email-address/verify-email-address.component';
import { StoreGuard } from './core/services/authentication/store.guard';

const routes: Routes = [
  {
    path: 'cancel-mobile-change-request',
    component: CancelMobileChangeRequestComponent,
  },
  {
    path: 'verify-email-address',
    component: VerifyEmailAddressComponent,
  },
  {
    path: 'otp-out',
    component: OtpOutComponent
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent
  },
  {
    path: 'otp-in',
    component: OtpInComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'login/:store',
    component: LoginComponent,
    canActivate: [StoreGuard]
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [RegisterGuard]
  },
  {
    path: '',
    loadChildren: './front/front.module#FrontModule',
  },
  {
    path: 'admin',
    loadChildren: './admin/admin.module#AdminModule',
  },
  {
    path: 'dashboard',
    loadChildren: './customer/customer.module#CustomerModule',
    // canActivate: [AuthGuard]
  },
  {
    path: 'merchant',
    loadChildren: './merchant/merchant.module#MerchantModule',
    // canActivate: [AuthGuard]
  },
  {
    path: '**',
    component: NotFoundComponent

  }

];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      scrollPositionRestoration: 'top',
      // anchorScrolling: 'enabled',
      // scrollOffset: [0, 0] // [x, y]
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
